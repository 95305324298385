import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import slugify from 'slugify'

import textPT from '../assets/data/hero-financie-seu-imovel-pt.json'
import textEN from '../assets/data/hero-financie-seu-imovel-en.json'

import { ILangFinancimentoEua } from '../../types'

import { Section } from './style'

const HeroYellowFi = ({ lang }: ILangFinancimentoEua) => {
  const pageText = lang === 'pt' ? textPT : textEN

  return (
    <Section id={slugify(pageText.title).toLowerCase()} className='py-5 py-md-3 d-flex align-items-md-center'>
      <div className='container d-flex align-items-center justify-content-md-end'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-8 col-xl-7 offset-md-4 offset-xl-5'>
            <h1
              className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mt-4'
              dangerouslySetInnerHTML={{ __html: pageText.title }}
            />
            <p
              className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mt-3 mb-3 pb-3'
              dangerouslySetInnerHTML={{ __html: pageText.description }}
            />
            <ScrollTo
              to='#form-yellowFi'
              section='dobra_01'
              sectionName={ pageText.title }
              elementName={pageText.textCTA}
            >
              <button
                className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 mw-100 text-none'
              >
                {pageText.textCTA}
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroYellowFi
