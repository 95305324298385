import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroYellowFi from './sections/HeroYellowFi/_index'
import YellowFiAgoraEInter from './sections/YellowFiAgoraEInter/_index'
import PorqueFinanciar from './sections/PorqueFinanciar/_index'
import QueroFinanciar from './sections/QueroFinanciar/_index'

const FinanciamentoImobiliarioEUA = () => {
  const lang = 'pt'

  return (
    <Layout pageContext={pageContext}>
      <HeroYellowFi lang={lang} />
      <YellowFiAgoraEInter lang={lang} />
      <PorqueFinanciar lang={lang} />
      <QueroFinanciar lang={lang} />
    </Layout>
  )
}

export default FinanciamentoImobiliarioEUA
